
import { defineComponent } from "vue";
import MainButton from "@/components/atoms/buttons/MainButton.vue";

export default defineComponent({
  name: "BlogSection",
  data() {
    return {};
  },
  components: {
    MainButton,
  },
  mounted() {},
  methods: {},
});
