
import { defineComponent } from "vue";
import BlogSection from "@/components/sections/admin/BlogSection.vue";
import AdminLayout from "@/templates/AdminLayout.vue";

export default defineComponent({
  name: "Blog",
  components: {
    BlogSection,
    AdminLayout,
  },
  mounted() {
    const token = localStorage.getItem('user');
    if(JSON.parse(localStorage.getItem('user')) == "user-token") {
      localStorage.removeItem('user');
      this.$router.push('/admin')
    }
  }
});
